@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: small;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.MuiInputBase-root {
  /* border-radius: 50px !important; */
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.bg-half-dark-reverse {
  background-image: linear-gradient(to bottom, rgb(0, 0, 0, 0.75), rgb(0, 0, 0, 0.35));
}
.bg-half-dark {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, rgb(0, 0, 0, 0.35), rgb(0, 0, 0, 0.75));
}
